import axios from "axios";
import "./common";
import config from "../config";

export default {
  getList: params => axios.get(`/sessions`, { params: params }),
  get: id => axios.get(`/sessions/${id}`),
  getHistoric: id => axios.get(`/sessions/${id}/historic`),
  create: (data, force = false) => {
    const f = force ? '?force=1' : ''
    return axios.post(`/sessions${f}`, data)
  },
  update: (id, data, force = false) => {
    const f = force ? '?force=1' : ''
    return axios.put(`/sessions/${id}${f}`, data)
  },
  delete: (id, params) => axios.delete(`/sessions/${id}`, { params: params }),
  checkBlock: (id, data) => axios.post(`/sessions/${id}/check-block`, data),
  validate: (id, data) => axios.patch(`/sessions/${id}/validate`, data),
  unvalidate: (id, data) => axios.patch(`/sessions/${id}/unvalidate`, data),
  toggleOnHold: (id, data) => axios.patch(`/sessions/${id}/onhold`, data),
  cancel: (id, data) => axios.patch(`/sessions/${id}/cancel`, data),
  export: params => axios.get(`/sessions/export`, { params: params }),
  print: params => axios.get(`/sessions/print`, { params: params }),
  getTrainerEvals: (id, params) =>
    axios.get(`/sessions/${id}/trainers-evaluations`, { params: params }),
  createTrainerEval: (session_id, data) =>
    axios.post(`/sessions/${session_id}/trainers-evaluations`, data),
  updateTrainerEval: (session_id, eval_id, data) =>
    axios.put(`/sessions/${session_id}/trainers-evaluations/${eval_id}`, data),
  updatePresences: (session_id, data) =>
    axios.put(`/sessions/${session_id}/presences`, data),
  participate: session_id => axios.put(`/sessions/${session_id}/participate`),
  validateParticipant: (session_id, participant_id) =>
    axios.patch(
      `/sessions/${session_id}/participants/${participant_id}/validate`
    ),
  getReviews: id => axios.get(`/sessions/${id}/reviews`),
  createReview: (session_id, data) =>
    axios.post(`/sessions/${session_id}/reviews`, data),
  updateReview(session_id, review_id, data) {
    data.append("_METHOD", "PUT");
    return axios.post(`/sessions/${session_id}/reviews/${review_id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
  },
  getTeacherEvals: (id, params) => axios.get(`/sessions/${id}/teachers-evaluations`, { params: params }),
  createTeacherEval: (session_id, data) =>
    axios.post(`/sessions/${session_id}/teachers-evaluations`, data),
  updateTeacherEval: (session_id, eval_id, data) =>
    axios.put(`/sessions/${session_id}/teachers-evaluations/${eval_id}`, data),
  todoDone: (session_id, todo_id) =>
    axios.patch(`/sessions/${session_id}/todo/${todo_id}/done`),
  todoNotDone: (session_id, todo_id) =>
    axios.patch(`/sessions/${session_id}/todo/${todo_id}/notdone`),
  enable: id => axios.patch(`/sessions/${id}/enable`),
  disable: id => axios.patch(`/sessions/${id}/disable`),
  logistics: params =>
    axios.get(`/logistics`, { responseType: "blob", params: params }),
  notify: id => axios.post(`/sessions/${id}/notify`),
  existingTicket: params =>
    axios.get(`/sessions/existing-ticket`, { params: params }),
  nextReference: () => axios.get(`/sessions/next-reference`),
  duplicate: id => axios.post(`/sessions/${id}/duplicate`),
  changeType: (id, type) => axios.put(`/sessions/${id}/change-type/${type}`),
  packageable: (id, params) =>
    axios.get(`/sessions/packageable/${id}`, { params: params }),
  package: (id, data, force = false) => {
    const f = force ? '?force=1' : ''
    return axios.post(`/sessions/package/${id}${f}`, data)
  },
  getSessionLocation(params) {
    return axios.get(`/sessions/maps`, {
      baseURL: config.ApiHost.replace(/api$/, "published"),
      params: params
    })
  },
  getVisitors(id) {
    return axios.get(`sessions/${id}/visitors`)
  },
  createVisitor(id, params) {
    return axios.post(`sessions/${id}/visitors`, params)
  },
  updateVisitor(id, idv, params) {
    return axios.put(`sessions/${id}/visitors/${idv}`, params)
  },
  sendVisitorsInvitations(id, visitors_ids) {
    return axios.post(`sessions/${id}/send-visitors-invitations`, {visitors_ids})
  }

};
