import axios from "axios";
import Config from "@/config";
import Auth from "@/plugins/auth";
import Router from "@/plugins/router";
import EventBus from "@/plugins/eventbus";

axios.defaults.baseURL = Config.ApiHost;
axios.defaults.headers.common["authorization"] = Config.PragmaToken;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  function(config) {
    if (Auth.authenticated) {
      if (config.headers) {
        config.headers["X-AuthToken"] = Auth.user.token;
      } else {
        config.headers = { "X-AuthToken": Auth.user.token };
      }
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(res) {
    return res;
  },
  function(res) {
    if (
      res.response &&
      res.response.status >= 400 &&
      res.response.status !== 423 &&
      res.response.status !== 401 &&
      res.response.data.errors
    ) {
      const errors = res.response.data.errors;

      if (errors instanceof Array) {
        for (let i = 0; i < errors.length; ++i) {
          EventBus.$emit("notifyError", errors[i]);
        }
      } else {
        EventBus.$emit("notifyError", errors.message);
      }
    } else if (res.response && res.response.status >= 500) {
      EventBus.$emit(
        "notifyError",
        "A server error occurred, please try again later"
      );
    } else if (res.response && res.response.status === 401) {
      Auth.localLogout();

      if (Router.app._route.name != 'login') {
        Router.push({ name: "login" });
      }
    }

    return Promise.reject(res);
  }
);

export default {};
