<template>
  <v-dialog
    v-if="isVisible"
    v-model="isVisible"
    persistent
    width="1000"
  >
    <div
      v-if="loading"
      class="ma-5 text-xs-center"
    >
      <v-progress-circular
        indeterminate
        :size="32"
        color="accent"
      />
    </div>
    <v-card v-else>
      <v-card-title>
        <p class="title mt-2 ml-2">
          Do you confirm the trainers participation and upgrade the session #{{ session.reference }} to Blocked?
        </p>
      </v-card-title>
      <v-card-text
        v-if="session"
        layout="column"
        layout-align="start start"
        class="full-wodth"
      >
        <div
          layout="row"
          class="full-wodth"
          flex="100"
          style="width: 100%"
        >
          <v-data-table
            v-if="trainers"
            :headers="headers"
            :items="trainers"
            hide-actions
            style="width: 100%"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <td
                layout="row"
                layout-align="start center"
              >
                <span>{{ props.item.firstname }} {{ props.item.lastname }} [{{ getTrainerAllocations(props.item.user_id, session.dates, allocations).month }} | {{ getTrainerAllocations(props.item.user_id, session.dates, allocations).year }}]</span>
                <v-icon
                  v-if="props.item.is_blocked"
                  title="Blocked"
                  class="ml-1"
                  small
                >
                  mdi-lock
                </v-icon>
              </td>
              <td
                v-for="d in props.item.dates"
                :key="d.id"
              >
                <v-checkbox
                  v-if="!props.item.is_request || d.was"
                  v-model="d.is"
                  hide-details
                />
              </td>
            </template>
          </v-data-table>
        </div>
        <div
          v-if="isActivity"
          layout="row"
          class="full-wodth"
          flex="100"
          style="width: 100%"
        >
          <v-data-table
            v-if="visitors"
            :headers="headersVisitors"
            :items="visitors"
            hide-actions
            style="width: 100%"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <td class="no-wrap">
                <div
                  layout="row"
                  layout-align="start center"
                >
                  <v-icon
                    v-if="isQueued(props.item, session, visitors)"
                    class="mr-2"
                    title="In the queue"
                  >
                    mdi-human-queue
                  </v-icon>
                  <span :class="{'font-italic grey--text': isQueued(props.item, session, visitors)}">{{ props.item.firstname }} {{ props.item.lastname }}</span>
                </div>
              </td>
              <td>
                <v-select
                  v-model="props.item.trainer_id"
                  :items="experts"
                  label="Expert"
                  :item-text="item => item.firstname + ' ' + item.lastname"
                  item-value="id"
                  hide-details
                  single-line
                  clearable
                  class="mt-0 pt-0"
                />
              </td>
              <td
                align="right"
              >
                <v-icon
                  color="red"
                  @click="removeVisitor(props.index)"
                >
                  mdi-close
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </div>
        <div
          layout="row"
          class="full-wodth"
          flex="100"
          style="width: 100%"
        >
          <v-textarea
            v-model="comment"
            outline
            label="Comment"
          />
        </div>
      </v-card-text>
      <v-card-actions
        layout="row"
        layout-align="end center"
        class="pr-4 pb-2"
      >
        <v-btn
          depressed
          color="error"
          @click="close"
        >
          No
        </v-btn>
        <v-btn
          depressed
          color="success"
          @click="save(0)"
        >
          Yes without notifying
        </v-btn>
        <v-btn
          depressed
          color="primary"
          @click="save(1)"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Params from '@/api/params'
import DateMixin from '@/mixins/date'
import SessionsMixin from '@/mixins/sessions'
import moment from 'moment'
import Sessions from '@/api/sessions'
import Consts from '@/consts'
import EventBus from '@/plugins/eventbus'

export default {
  mixins: [DateMixin, SessionsMixin],
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    sess: {
      required: true
    },
  },
  data () {
    return {
      loading: true,
      headers: [],
      headersVisitors: [
        { text: "Intern", value: "name", sortable: false, width: '50%' },
        { text: "Expert", value: "expert", sortable: false },
        { text: "", value: "", sortable: false, align: 'right', width: '50px' }
      ],
      list: [],
      trainers: [],
      visitors: [],
      session: null,
      comment: "",
      usersTrainers: [],
      trainersAdded: [],
      trainersDeleted: [],
      visitorsAdded: [],
      visitorsDeleted: [],
      allocations: {}
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (x) {
        this.$emit('update:visible', x)
      }
    },
    isActivity() {
      if (parseInt(this.session.session_type) == Consts.ACTIVITY) {
        return true;
      }
      return false;
    },
    experts(){
      var users = []
      const tIds = this.trainers.map(function (el) { return el.user_id })

      // Trainer
      this.usersTrainers.forEach(u => {
        if(tIds.indexOf(u.id) > -1){
          users.push(u)
        }
      })

      users = users.sort(
        (a, b) =>
          a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
      )

      const arr = []

      // Expert
      if(this.session.topic_id){
        users.forEach(u => {
          if(u.skills){
            let found = false
            u.skills.forEach(s => {
              if(s.topic_id === this.session.topic_id){
                if(s.role == Consts.SKILL_ROLE.EXPERT){
                  found = true
                }
              }
            })

            if(found){
              arr.push(u)
            }
          }
        })
      }

      return arr
    },
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.fetchParams()
      }
    }
  },
  mounted () {
    EventBus.$on('trainer-added', (user) => {
      this.trainersAdded.push(user)
      const idx = this.trainersDeleted.indexOf(user.id)
      if (idx > -1) {
        this.trainersDeleted.splice(idx, 1)
      }
    })
    EventBus.$on('trainer-deleted', (user) => {
      this.trainersDeleted.push(user.user_id)
      const idx = this.trainersAdded.map(function (t) { return t.id }).indexOf(user.user_id)
      if (idx > -1) {
        this.trainersAdded.splice(idx, 1)
      }
    })
    EventBus.$on('visitor-clean', () => {
      this.visitorsAdded.splice(0)
      this.visitorsDeleted.splice(0)
      this.visitors.splice(0)
    })
    EventBus.$on('visitor-added', (user) => {
      this.visitorsAdded.push(user)
      //je pars du principe que si l'utilisateur a une propriété unregister_email alors c'est qu'on est sur une session de journée d'introduction
      const deleted = this.visitorsDeleted.find(d => user.unregister_email ? d.unregister_email == user.unregister_email : d.id == user.id)
      const idx = this.visitorsDeleted.indexOf(deleted)
      if (idx > -1) {
        this.visitorsDeleted.splice(idx, 1)
      }
    })
    EventBus.$on('visitor-deleted', (user) => {
      this.visitorsDeleted.push(user.unregister_email ? user.unregister_email : user.user_id)
      const idx = this.visitorsAdded.map(function (t) {
        return user.unregister_email ? t.unregister_email : t.id }).indexOf(user.unregister_email ? user.unregister_email : user.user_id)

      if (idx > -1) {
        this.visitorsAdded.splice(idx, 1)
      }
    })
  },
  methods: {
    removeVisitor(index) {
      this.visitors.splice(index, 1);
    },
    close () {
      this.list = []
      this.trainers = []
      this.visitors = []
      this.comment = ""
      this.isVisible = false
    },
    fetchParams () {
      this.loading = true
      Sessions.get(this.sess.id).then(res => {
        this.session = res.data
        this.loading = false

        if (this.session.dates.length > 0) {
          // Parse dates into split date/time
          for (let i = 0; i < this.session.dates.length; ++i) {
            let d = this.session.dates[i]

            d.date_start = moment(d.start).format('YYYY-MM-DD')
            d.date_start_text = moment(d.start).format('YYYY-MM-DD')
            d.clock_start = moment(d.start).format('HH:mm:ss')
            d.clock_start_text = moment(d.start).format('HH:mm')
            d.clock_end = moment(d.end).format('HH:mm:ss')
            d.clock_end_text = moment(d.end).format('HH:mm')

            this.session.dates[i] = d
          }
        }
        this.loadDates()

        if (this.session.session_visitor && this.session.session_visitor.length > 0) {
          for (let i = 0; i < this.session.session_visitor.length; i++) {

            if (this.visitorsDeleted.indexOf(this.session.session_visitor[i].unregister_email ? this.session.session_visitor[i].unregister_email : this.session.session_visitor[i].user_id) == -1) {
              console.log("userid", this.session.session_visitor[i].user_id)
              this.visitors.push({
                id: this.session.session_visitor[i].id,
                user_id: this.session.session_visitor[i].user_id ?? null,
                trainer_id: this.session.session_visitor[i].trainer_id ?? null,
                firstname: this.session.session_visitor[i].user_id ? this.session.session_visitor[i].user.firstname : this.session.session_visitor[i].unregister_firstname,
                lastname: this.session.session_visitor[i].user_id ? this.session.session_visitor[i].user.lastname : this.session.session_visitor[i].unregister_lastname,
                unregister_firstname: this.session.session_visitor[i].unregister_firstname,
                unregister_lastname: this.session.session_visitor[i].unregister_lastname,
                unregister_email: this.session.session_visitor[i].unregister_email,
                unregister_phone: this.session.session_visitor[i].unregister_phone
              });
            }
          }
        }

        for (let i = 0; i < this.visitorsAdded.length; i++) {
          const t = this.visitorsAdded[i]
          console.log(t)
          this.visitors.push({
            id: t.unregister_email ? t.id : null, //tantôt un faux user (intro days)
            user_id: t.unregister_email ? null : t.id, //tantôt un vrai user
            trainer_id: null,
            firstname: t.id ? t.firstname : t.unregister_firstname,
            lastname: t.id ? t.lastname : t.unregister_lastname,
            unregister_firstname: t.unregister_firstname,
            unregister_lastname: t.unregister_lastname,
            unregister_email: t.unregister_email,
            unregister_phone: t.unregister_phone
          })
        }
      }, () => {
        this.$router.push({ name: 'sessions-index' })
      })
      Params.getList('users/allocations').then(res => {
        this.allocations = res.data
      })
      Params.getList("groups", { role: Consts.TRAINER }).then(res => {
        let groups = res.data;
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].users && groups[i].users.length > 0) {
            for (let j = 0; j < groups[i].users.length; j++) {
              if (groups[i].users[j].is_enabled) {
                groups[i].users[j]["fullname"] =
                  groups[i].users[j]["firstname"] +
                  " " +
                  groups[i].users[j]["lastname"];
                this.usersTrainers.push(groups[i].users[j]);
              }
            }
          }
        }

        //Supprime les doublons dans plusieurs groupes
        var uniq = new Set(this.usersTrainers.map(e => JSON.stringify(e)));
        var result = Array.from(uniq).map(e => JSON.parse(e));
        this.usersTrainers = result;
      });
    },
    formatDate (x) {
      return moment(x).format('YYYY.MM.DD')
    },
    formatTime (x) {
      return moment(x).format('HH:mm')
    },
    formatDateHuman (date) {
      return moment(date).format('ddd, MMM DD YYYY, HH:mm')
    },
    getDates (start, end) {
      if (this.formatDate(start) == this.formatDate(end)) {
        return this.formatDateHuman(start) + " until " + this.formatTime(end)
      }
      else {
        return this.formatDateHuman(start) + " - " + this.formatDateHuman(end)
      }
    },
    loadDates () {
      if (this.session.dates && this.session.dates.length > 0) {
        let arr = [
          {
            value: 0,
            text: "Trainer",
            width: '50%'
          }
        ]
        let dates = this.session.dates
        dates.sort((a, b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0))

        for (let i = 0; i < dates.length; i++) {
          let d = this.getDates(dates[i].start, dates[i].end)
          arr.push({
            text: d,
            value: dates[i].id,
            sortable: 0
          })
        }
        this.headers = arr

        for (let i = 0; i < this.trainersAdded.length; i++) {
          const t = this.trainersAdded[i]
          this.session.trainers_presences.push({
            user_id: t.id,
            user: t,
            is_request: 0,
            presences: []
          })
        }

        if (this.session.trainers_presences && this.session.trainers_presences.length > 0) {

          for (let i = 0; i < this.session.trainers_presences.length; i++) {
            if (this.trainersDeleted.indexOf(this.session.trainers_presences[i].user_id) == -1) {
              this.list[this.session.trainers_presences[i].user_id] = {
                user_id: this.session.trainers_presences[i].user_id,
                firstname: this.session.trainers_presences[i].user.firstname,
                lastname: this.session.trainers_presences[i].user.lastname,
                dates: [],
                is_request: this.session.trainers_presences[i].is_request,
                is_blocked: this.session.trainers_presences[i].is_blocked
              }
              for (let j = 0; j < this.session.dates.length; j++) {
                let is = this.session.trainers_presences[i].presences.map(function (e) { return e.session_date_id }).indexOf(this.session.dates[j].id)
                if (is > -1) {
                  this.list[this.session.trainers_presences[i].user_id].dates.push({
                    date_id: this.session.dates[j].id,
                    start: this.session.dates[j].start,
                    en: this.session.dates[j].end,
                    is: false,
                    was: true
                  })
                }
                else {
                  this.list[this.session.trainers_presences[i].user_id].dates.push({
                    date_id: this.session.dates[j].id,
                    start: this.session.dates[j].start,
                    en: this.session.dates[j].end,
                    is: false,
                    was: false
                  })
                }
              }
            }
          }

          for (let i in this.list) {
            this.trainers.push(this.list[i])
          }
        }
      }
    },
    _prepare () {
      for (let i = 0; i < this.session.dates.length; ++i) {
        const d = this.session.dates[i]

        d.start = d.date_start + ' ' + d.clock_start
        d.end = d.date_start + ' ' + d.clock_end

        this.session.dates[i] = d
      }
    },
    save (notif) {
      this.loading = true

      this._prepare()

      let paramsTrainers = []
      for (let t = 0; t < this.trainers.length; t++) {
        let d = {}
        for (let p = 0; p < this.trainers[t].dates.length; p++) {
          if (this.trainers[t].dates[p].is) {
            d[this.trainers[t].dates[p].date_id] = true
          }
        }
        paramsTrainers.push({
          user_id: this.trainers[t].user_id,
          dates: d
        })
      }

      Sessions.checkBlock(this.session.id, {trainers: paramsTrainers, dates: this.session.dates, visitors: this.visitors}).then(() => {
        Sessions.update(this.session.id, { trainers: paramsTrainers, visitors: this.visitors, dates: this.session.dates }).then(this.validate(notif), (e) => {
          this.$snotify.warning(e.response.data.errors.message, {
            timeout: false,
            closeOnClick: false,
            buttons: [
              { text: 'Understood !', action: (toast) => { this.$snotify.remove(toast.id) }, bold: true },
              { text: 'Save anyway !', action: (toast) => {
                this.$snotify.remove(toast.id)
                Sessions.update(this.session.id, { trainers: paramsTrainers, visitors: this.visitors, dates: this.session.dates }, true).then(this.validate(notif)).catch(() => {
                  this.$snotify.error('An error occured')
                })
              }, bold: true }
            ]
          });
          this.loading = false
        })
      }, () => {
        this.loading = false
      })
    },
    validate(notif) {
      return () => { //pour inférer "notif"
        Sessions.validate(this.session.id, { to_send: notif, comment: this.comment }).then(res => {
          this.$snotify.success('The session has been upgraded')

          this.isVisible = false
          this.loading = false
          this.session = null
          this.list = []
          this.trainers = []
          this.visitors = []
          this.visitorsAdded.splice(0)
          this.visitorsDeleted.splice(0)
          this.comment = ""
          this.$emit('updated', res.data)
        }, () => {
          this.loading = false
        })
      }
    }
  }
}
</script>
