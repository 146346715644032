import Consts from "@/consts";
import moment from "moment";
import Auth from "@/plugins/auth";
import Sessions from "@/api/sessions";

export default {
  computed: {
    animatorRoles() {
      return [
        { text: "Teacher", value: Consts.ANIMATOR_ROLE.TEACHER },
        {
          text: "Specialized animator",
          value: Consts.ANIMATOR_ROLE.SPECIALIZED
        },
        { text: "Mixed", value: Consts.ANIMATOR_ROLE.MIXED }
      ];
    },
    moments() {
      return [
        { text: "Morning", value: "morning" },
        { text: "Afternoon", value: "afternoon" },
        { text: "Evening", value: "evening" },
        { text: "Fullday", value: "day" }
      ];
    }
  },
  methods: {
    sessionTypeStr(x = 3) {
      switch (this.ensureInt(x)) {
        case Consts.EVENTS:
          return "Events";
        case Consts.ADULTS:
          return "Adults";
        case Consts.SCHOOL:
          return "School";
        case Consts.TEACHERS:
          return "Teachers";
        case Consts.YOUTH:
          return "Youth";
        case Consts.OTHER:
          return "Other";
        case Consts.INTERNAL:
          return "Internal";
        case Consts.FREELANCES_TRAINING:
          return "Training";
        case Consts.ACTIVITY:
          return "Activity";
        case Consts.INTRODUCTION_DAY:
          return "Introduction day";
        default:
          return x;
      }
    },
    sessionTypeStrLong(x = 3) {
      switch (this.ensureInt(x)) {
        case Consts.EVENTS:
          return "Events";
        case Consts.ADULTS:
          return "Adults training";
        case Consts.SCHOOL:
          return "School training";
        case Consts.TEACHERS:
          return "Teachers training";
        case Consts.YOUTH:
          return "Youth training";
        case Consts.OTHER:
          return "Other";
        case Consts.INTERNAL:
          return "Internal meeting";
        case Consts.FREELANCES_TRAINING:
          return "Freelances training";
        case Consts.ACTIVITY:
          return "Freelances activity";
        case Consts.INTRODUCTION_DAY:
          return "Introduction day";
        default:
          return x;
      }
    },
    sessionTypeChar(x = 3) {
      switch (this.ensureInt(x)) {
        case Consts.EVENTS:
          return "E";
        case Consts.ADULTS:
          return "A";
        case Consts.SCHOOL:
          return "S";
        case Consts.TEACHERS:
          return "T";
        case Consts.YOUTH:
          return "Y";
        case Consts.OTHER:
          return "O";
        case Consts.INTERNAL:
          return "I";
        case Consts.FREELANCES_TRAINING:
          return "T";
        case Consts.ACTIVITY:
          return "A";
        case Consts.INTRODUCTION_DAY:
          return "D";
        default:
          return x;
      }
    },
    sessionTypeColor(x = 3, hexa = false) {
      switch (this.ensureInt(x)) {
        case Consts.EVENTS:
          return hexa ? "#795548" : "brown";
        case Consts.ADULTS:
          return hexa ? "#9C27B0" : "purple";
        case Consts.SCHOOL:
          return hexa ? "#8BC34A" : "light-green";
        case Consts.TEACHERS:
          return hexa ? "#FF5722" : "deep-orange";
        case Consts.YOUTH:
          return hexa ? "#00BCD4" : "cyan";
        case Consts.OTHER:
          return hexa ? "#FF9800" : "amber";
        case Consts.INTERNAL:
          return hexa ? "#607D8B" : "blue-grey";
        case Consts.FREELANCES_TRAINING:
          return hexa ? "#60FDDE " : "training";
        case Consts.ACTIVITY:
          return hexa ? "#A5BDFF" : "activity";
        case Consts.INTRODUCTION_DAY:
          return hexa ? "#239b56" : "#abebc6";
        default:
          return hexa ? "#9E9E9E" : "grey";
      }
    },
    sessionTypeColorLight(x = 3, hexa = false) {
      switch (this.ensureInt(x)) {
        case Consts.EVENTS:
          return hexa ? "#A1887F" : "brown lighten-2";
        case Consts.ADULTS:
          return hexa ? "#BA68C8" : "purple lighten-2";
        case Consts.SCHOOL:
          return hexa ? "#AED581" : "light-green lighten-2";
        case Consts.TEACHERS:
          return hexa ? "#FF8A65" : "deep-orange lighten-2";
        case Consts.YOUTH:
          return hexa ? "#4DD0E1" : "cyan lighten-2";
        case Consts.OTHER:
          return hexa ? "#FFD54F" : "amber lighten-2";
        case Consts.INTERNAL:
          return hexa ? "#90A4AE" : "blue-grey lighten-2";
        case Consts.FREELANCES_TRAINING:
          return hexa ? "#81fee5" : "training lighten-2";
        case Consts.ACTIVITY:
          return hexa ? "#b3c7ff" : "activity lighten-2";
        case Consts.INTRODUCTION_DAY:
          return hexa ? "#abebc6" : "#abebc6 lighten-2";
        default:
          return hexa ? "#E0E0E0" : "grey lighten-2";
      }
    },
    sessionTypeColorLighten(x = 3, hexa = false) {
      switch (this.ensureInt(x)) {
        case Consts.EVENTS:
          return hexa ? "#D7CCC8" : "brown lighten-4";
        case Consts.ADULTS:
          return hexa ? "#E1BEE7" : "purple lighten-4";
        case Consts.SCHOOL:
          return hexa ? "#DCEDC8" : "light-green lighten-4";
        case Consts.TEACHERS:
          return hexa ? "#FFCCBC" : "deep-orange lighten-4";
        case Consts.YOUTH:
          return hexa ? "#B2EBF2" : "cyan lighten-4";
        case Consts.OTHER:
          return hexa ? "#FFECB3" : "amber lighten-4";
        case Consts.INTERNAL:
          return hexa ? "#CFD8DC" : "blue-grey lighten-4";
        case Consts.FREELANCES_TRAINING:
          return hexa ? "#b3feef" : "training lighten-4";
        case Consts.ACTIVITY:
          return hexa ? "#e6ecff" : "activity lighten-4";
        case Consts.INTRODUCTION_DAY:
          return hexa ? "#abebc6" : "#abebc6 lighten-4";
        default:
          return hexa ? "#F5F5F5" : "grey lighten-4";
      }
    },
    sessionStatusStr(x = 2) {
      switch (this.ensureInt(x)) {
        case Consts.CANCELLED:
          return "Ca";
        case Consts.CONFIRMED:
          return "Cf";
        case Consts.RELEASE:
          return "Rl";
        case Consts.TOVALIDATE:
          return "V";
        case Consts.BLOCKED:
          return "B";
        case Consts.TOEVALUATE:
          return "E";
        case Consts.CLOSED:
          return "C";
        case Consts.DRAFT:
          return "D";
        case Consts.ONHOLD:
          return "H";
        default:
          return x;
      }
    },
    sessionStatusStrLong(x = 2) {
      switch (this.ensureInt(x)) {
        case Consts.CANCELLED:
          return "Cancelled";
        case Consts.CONFIRMED:
          return "Confirmed";
        case Consts.RELEASE:
          return "Release";
        case Consts.TOVALIDATE:
          return "To validate";
        case Consts.BLOCKED:
          return "Blocked";
        case Consts.TOEVALUATE:
          return "To evaluate";
        case Consts.CLOSED:
          return "Closed";
        case Consts.DRAFT:
          return "Draft";
        case Consts.ONHOLD:
          return "On hold";
        default:
          return x;
      }
    },
    sessionStatusColor(x = 2, hexa = false) {
      switch (this.ensureInt(x)) {
        case Consts.CANCELLED:
          return hexa ? "#9E9E9E" : "grey";
        case Consts.CONFIRMED:
          return hexa ? "#ebdef0" : "#ebdef0";
        case Consts.RELEASE:
          return hexa ? "#C62828" : "red darken-3";
        case Consts.TOVALIDATE:
          return hexa ? "#FFC107" : "amber";
        case Consts.BLOCKED:
          return hexa ? "#4CAF50" : "green";
        case Consts.TOEVALUATE:
          return hexa ? "#00BCD4" : "cyan";
        case Consts.CLOSED:
          return hexa ? "#EEEEEE" : "grey lighten-3";
        case Consts.DRAFT:
          return hexa ? "#424242" : "blue-grey darken-2";
        case Consts.ONHOLD:
          return hexa ? "#3F51B5" : "indigo";
        default:
          return hexa ? "#9E9E9E" : "grey";
      }
    },
    ensureInt(x) {
      return Number.isInteger(x) ? x : parseInt(x, 10);
    },
    getInternalType(t) {
      switch (t) {
        case Consts.INTERNALTRAINER:
          return "Trainer";
        case Consts.INTERNALSTUDENT:
          return "Student";
        default:
          return t;
      }
    },
    getTrainerAllocations(userId, dates = [], allocations = {}) {
      let date = null;
      for (let i = 0; i < dates.length; i++) {
        const d = dates[i];
        if (!date || moment(d.date_start).isBefore(date)) {
          date = d.date_start;
        }
      }

      const y = moment(date).format("YYYY");
      const m = moment(date).format("MM");

      if (allocations[userId] && allocations[userId][y]) {
        return {
          year: allocations[userId][y].total,
          month: allocations[userId][y][m]
        };
      }

      return {
        year: 0,
        month: 0
      };
    },
    isQueued(item, s, session_visitor) {
      if (s.session_type != Consts.FREELANCES_TRAINING && s.session_type != Consts.INTRODUCTION_DAY) {
        return false;
      }
      if (!s.nb_participants) {
        return false;
      }

      let idx = session_visitor.indexOf(item)

      if (idx + 1 > s.nb_participants) {
        return true;
      }

      return false;
    },
    canTrain(item, p) {
      if (p.is_blocked) {
        return false;
      }

      return this.canDeliverSess(item);
    },
    canDeliverSess(item) {
        if (item.status == Consts.BLOCKED || item.status == Consts.TOEVALUATE) {
            return false;
        }

        let blocked = false
        item.trainers_presences.forEach(t => {
          if (t.user_id === Auth.user.id && t.is_blocked) {
            blocked = true
          }
        })

        if (blocked) {
          return false
        }

        // Formation FL
        if (item.session_type === Consts.FREELANCES_TRAINING || item.session_type === Consts.INTRODUCTION_DAY) {
            if (Auth.user.skills && Auth.user.skills.length) {
                let found = false;
                Auth.user.skills.forEach(s => {
                    if (s.topic_id === item.topic_id) {

                        // Pas enseignant
                        if (item.session_type === Consts.INTRODUCTION_DAY || s.role != Consts.SKILL_ROLE.TEACHER) {
                          if(item.application == Consts.APPLICATION.BEESECURE && s.role == Consts.SKILL_ROLE.BS_TRAINER) {
                            found = true
                          }
                          else {
                            let season = null;

                            // On doit aussi avoir la saison
                            if (Auth.user.seasons && Auth.user.seasons.length) {
                                Auth.user.seasons.forEach(s => {
                                    if (s.season_id === item.season_id) {
                                        season = s;
                                    }
                                });
                            }

                            found = season && s.is_former
                          }
                        } else {
                            found = true
                        }
                    }
                })

                return found
            }

            return false;
        }
        // Activité FL
        else if (item.session_type === Consts.ACTIVITY) {
            if (Auth.user.skills && Auth.user.skills.length) {
              let found = false;
              const year = moment(item.dates[0].start).format("YYYY");
              Auth.user.skills.forEach(s => {
                  if (s.topic_id === item.topic_id) {
                  switch (item.animator_role) {
                      case Consts.ANIMATOR_ROLE.TEACHER:
                        if (s.role == Consts.SKILL_ROLE.TEACHER) {
                            found = true;
                        }
                      break;
                      case Consts.ANIMATOR_ROLE.SPECIALIZED:
                        if (s.role == Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR) {
                            found = true;
                        }
                        if (s.role == Consts.SKILL_ROLE.EXPERT) {
                            found = true;
                        }
                      break;
                      case Consts.ANIMATOR_ROLE.MIXED:
                        if (s.role == Consts.SKILL_ROLE.TEACHER) {
                            found = true;
                        }
                        if (s.role == Consts.SKILL_ROLE.SPECIALIZED_ANIMATOR) {
                            found = true;
                        }
                        if (s.role == Consts.SKILL_ROLE.EXPERT) {
                            found = true;
                        }
                      break;
                  }

                  // Pas enseignant
                  if (s.role != Consts.SKILL_ROLE.TEACHER) {
                      let contract = null;
                      let season = null;

                      // On doit aussi avoir un contrat
                      Auth.user.contracts.forEach(c => {
                      if (
                          c.year == year &&
                          c.fl_label &&
                          c.fl_criminal_records &&
                          c.fl_resume
                      ) {
                          contract = c;
                      }
                      });

                      // On doit aussi avoir la saison
                      if (Auth.user.seasons && Auth.user.seasons.length) {
                        Auth.user.seasons.forEach(s => {
                            if (s.season_id === item.season_id) {
                              season = s;
                            }
                        });
                      }

                      found = found && season && contract;
                  }
                }
              });

              return found;
            }

            return false;
        }

        return true;
    },
    isTrainingSess(s) {
      return parseInt(s.session_type) == Consts.FREELANCES_TRAINING;
    },
    isIntroductionDaySess(s) {
      return parseInt(s.session_type) == Consts.INTRODUCTION_DAY;
    },
    isActivitySess(s) {
      return parseInt(s.session_type) == Consts.ACTIVITY;
    },
    canParticipate(item) {
      if(this.isIntroductionDaySess(item)) {
        return false;
      }
      if (item.status == Consts.BLOCKED || item.status == Consts.TOEVALUATE) {
        return false;
      }
      if (item.visitors && item.visitors.length) {
        let found = false;
        item.visitors.forEach(v => {
          if (v.id === Auth.user.id) {
            found = true;
          }
        });

        if (found) {
          return false;
        }
      }
      if (this.isActivitySess(item)) {
        if (item.animator_role == Consts.ANIMATOR_ROLE.TEACHER) {
          return false;
        }

        if (Auth.user.skills && Auth.user.skills.length) {
          let found = false;
          Auth.user.skills.forEach(s => {
            if (s.topic_id === item.topic_id) {
              if (s.role == Consts.SKILL_ROLE.INTERN || s.role == Consts.SKILL_ROLE.BS_CANDIDATE) {
                  found = true;
              }
            }
          });

          return found;
        }
      }

      return true;
    },
    canUnparticipate(item) {
      if(this.isIntroductionDaySess(item)) {
        return false;
      }
      if (item.status == Consts.BLOCKED || item.status == Consts.TOEVALUATE) {
        return false;
      }
      if (item.session_visitor && item.session_visitor.length) {
        let found = false;
        item.session_visitor.forEach(v => {
          if (v.user_id === Auth.user.id && !v.is_blocked) {
            found = true;
          }
        });

        if (found) {
          return true;
        }
      }

      return false;
    },
    participate(sess, cb = null) {
      Sessions.participate(sess.id).then(res => {
        if (this.canParticipate(sess)) {
          sess.visitors.push(Auth.user);
          sess.session_visitor.push({
            user_id: Auth.user.id,
            user: Auth.user
          });

          if (res.data.pos) {
            this.$snotify.success(
              "Participation has been added, you are #" +
                res.data.pos +
                " in the queue"
            );
          } else {
            this.$snotify.success("Participation has been added");
          }
        } else {
          let idx = 0;
          for (let i = 0; i < sess.visitors.length; i++) {
            const v = sess.visitors[i];
            if (v.id === Auth.user.id) {
              idx = i;
            }
          }
          sess.visitors.splice(idx, 1);

          idx = 0;
          for (let i = 0; i < sess.session_visitor.length; i++) {
            const v = sess.session_visitor[i];
            if (v.user_id === Auth.user.id) {
              idx = i;
            }
          }
          sess.session_visitor.splice(idx, 1);

          this.$snotify.success("Participation has been removed");
        }
        if (cb) {
          cb()
        }
      });
    },
    textDowngrade(sess) {
      switch (sess.status) {
        case Consts.CONFIRMED:
          return (
            "Are you sure you want to downgrade the session #" +
            sess.reference +
            "? The session will be on Draft."
          );
        case Consts.RELEASE:
          return (
            "Are you sure you want to downgrade the session #" +
            sess.reference +
            "? The session status will be Confirmed."
          );
        case Consts.BLOCKED:
          return (
            "Are you sure you want to downgrade the session #" +
            sess.reference +
            "? The session will be to Validate."
          );
        case Consts.TOVALIDATE:
          return (
            "Are you sure you want to downgrade the session #" +
            sess.reference +
            "? The session will be Released."
          );
        case Consts.TOEVALUATE:
          return (
            "Are you sure you want to downgrade the session #" +
            sess.reference +
            "? The session will be Blocked."
          );
        case Consts.CLOSED:
          return (
            "Are you sure you want to downgrade the session #" +
            sess.reference +
            "? The session will be to Evaluate."
          );
      }
    },
    textUpgrade(sess) {
      switch (sess.status) {
        case Consts.DRAFT:
          return "Do you want to upgrade this session? Its status will be Confirmed?";
        case Consts.CANCELLED:
          return "This session has been cancelled, do you want to activate it again?";
        case Consts.CONFIRMED:
          if (sess.organization && sess.contact) {
            return (
              "Do you accept the " +
              sess.contact.firstname +
              " " +
              sess.contact.lastname +
              "'s request at the organization " +
              sess.organization.name +
              "? This session will be Released."
            );
          } else {
            return "Do you accept the request? This session will be Released";
          }
        case Consts.RELEASE:
          return "Do you want to upgrade this session? Its status will be To validate?";
        case Consts.TOVALIDATE:
          return "Do you confirm the trainers participation and upgrade this session to Blocked?";
        case Consts.BLOCKED:
          return "Do you want to upgrade this session? Its status will be to Evaluate?";
        case Consts.TOEVALUATE:
          return "Do you want to upgrade the session status to Closed?";
      }
    },
  }
};
