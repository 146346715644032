<template>
  <div>
    <div v-if="isEditingMode" class="tgp-groups">
      <div v-if="availableGroups.length == 0">
        <em>Aucun type de groupe disponible</em>
        <div class="tgp-actions">
          <a @click="cancelEdit()">Cancel</a>
        </div>
      </div>
      <div v-else>
        <label>Please choose the type of groups you want to add</label>
        <v-select
          v-model="currentGroup.id"
          label="Type of groups"
          :items="availableGroups"
          item-text="name"
          item-value="id"
          class="field-width"
          @change="onGroupChanged"
          outline
        />
        <div v-if="currentGroup.id">
          <label>What target groups you want to link to this topic ?</label>
          <v-radio-group
            v-model="currentGroup.is_full"
            @change="onSelectFullGroup"
            row
          >
            <v-radio
              label="All target groups of this type"
              :value="true"
            ></v-radio>
            <v-radio
              label="A selection of target groups of this type"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </div>
        <div class="tgp-selector" v-if="!currentGroup.is_full">
          <div class="tgp-selector-available">
            <strong>Available target groups</strong>
            <div
              v-for="tg in availableTargetGroups"
              :key="tg.id"
              @click="selectForMoving(tg)"
              class="tgp-item"
              :class="{selected: tgp_to_select.indexOf(tg) >= 0}"
            >
                {{tg.name}}
            </div>
          </div>
          <div class="tgp-selector-actions">
            <v-btn
              @click="selectAll"
              :disabled="availableTargetGroups.length == 0"
              title="Select all"
            >
              <v-icon>mdi-chevron-right</v-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              @click="selectTargetGroups"
              :disabled="tgp_to_select.length == 0"
              title="Select these items"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              @click="unselectTargetGroups"
              :disabled="tgp_to_unselect.length == 0"
              title="Remove these items"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              @click="unselectAll"
              :disabled="currentGroup.targets.length == 0"
              title="Unselect all"
            >
              <v-icon>mdi-chevron-left</v-icon>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <div class="tgp-selector-selected">
            <strong>Selected target groups</strong>
            <div
              v-for="tg in sortedCurrentTargetGroups"
              :key="tg.id"
              @click="unselectForMoving(tg)"
              class="tgp-item"
              :class="{selected: tgp_to_unselect.indexOf(tg) >= 0}"
            >
                {{tg.name}}
            </div>
          </div>
        </div>
        <div class="tgp-actions">
          <v-btn @click="save"><span>{{textSaveButton}}</span></v-btn><span>or</span><a @click="cancelEdit()">Cancel</a>
        </div>
      </div>
    </div>
    <div v-else-if="groupInfo" class="show-me">
      <div class="main-info">
        <div class="head">{{groupInfo.name}}</div>
        <div>
          <div v-if=" ! currentGroup.is_full">
            <div
              v-for="t in currentGroup.targets"
              :key="t.id"
              class="target-chips"
            >
              {{t.name}}
            </div>
          </div>
          <em v-else>All target groups of this type are concerned</em>
        </div>
      </div>
      <div class="show-actions">
        <v-btn
          class="mx-0"
          icon
          @click="initEdition"
          title="Edit this type of groups"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <delete-button
          :label="groupInfo.name"
          title="Remove this type of groups"
          @confirm="$emit('delete')"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import Params from "@/api/params"
  import DeleteButton from "@/components/shared/DeleteButton.vue"

  export  default {
    components: {DeleteButton},
    props: {
      group: {
        type: Object,
        required: true
      },
      groupstypes: {
        type: Array,
        required: true
      },
      reservedIds: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        currentGroup: {id: null, is_full: true, targets: [], is_editing: true},
        target_groups: [],
        tgp_to_select: [],
        tgp_to_unselect: [],
        isEditingMode: false,
        hasAlreadyBeenAdded: false,
        copyOfCurrentGroup: {id: null, is_full: true, targets: []}
      }
    },
    mounted() {
      this.currentGroup = Object.assign(this.currentGroup, structuredClone(this.group))
      this.isEditingMode = !this.currentGroup.id
      this.hasAlreadyBeenAdded = this.currentGroup.id

    },
    methods: {
      selectTargetGroups() {
        this.tgp_to_select.forEach(t => {
          this.currentGroup.targets.push(t)
        })
        this.tgp_to_select.splice(0)
      },
      unselectTargetGroups() {
        this.tgp_to_unselect.forEach(t => {
          this.currentGroup.targets.splice(this.currentGroup.targets.indexOf(t), 1)
        })
        this.tgp_to_unselect.splice(0)
      },
      selectAll() {
        this.availableTargetGroups.forEach(t => {
          this.currentGroup.targets.push(t)
        })
      },
      unselectAll() {
        this.currentGroup.targets.splice(0)
      },
      selectForMoving(tg) {
        if(this.tgp_to_select.indexOf(tg) === -1) {
          this.tgp_to_select.push(tg)
        }
        else {
          this.tgp_to_select.splice(this.tgp_to_select.indexOf(tg), 1)
        }
      },
      unselectForMoving(tg) {
        if(this.tgp_to_unselect.indexOf(tg) === -1) {
          this.tgp_to_unselect.push(tg)
        }
        else {
          this.tgp_to_unselect.splice(this.tgp_to_unselect.indexOf(tg), 1)
        }
      },
      async onSelectFullGroup() {
        this.target_groups = []
        if( ! this.currentGroup.is_full) {
          const {data} = await Params.getList("targets-groups", {is_enabled: 1, sortBy: 'name', groupstype_id: this.currentGroup.id})
          this.target_groups = data
        }
        else {
          this.currentGroup.targets.splice(0) //on vide tout
          this.tgp_to_select.splice(0)
          this.tgp_to_unselect.splice(0)
        }
      },
      onGroupChanged() {
        this.currentGroup.is_full = true
        this.currentGroup.targets.splice(0) //on vide tout
        this.tgp_to_select.splice(0)
        this.tgp_to_unselect.splice(0)
        this.target_groups = []
      },
      cancelEdit() {
        if( ! this.group.id) {
          this.$emit('cancel')
        }
        else {
          this.currentGroup = Object.assign(this.currentGroup, this.copyOfCurrentGroup)
          this.isEditingMode = false
          this.currentGroup.is_editing = false
          this.$emit("stop-editing")

        }
      },
      save() {
        this.isEditingMode = false
        this.hasAlreadyBeenAdded = true
        this.currentGroup.is_editing = false
        this.$emit('save', this.currentGroup)
      },
      async initEdition() {
        this.copyOfCurrentGroup = structuredClone(this.currentGroup)
        if(!this.currentGroup.is_full) {
          const {data} = await Params.getList("targets-groups", {is_enabled: 1, sortBy: 'name', groupstype_id: this.currentGroup.id})
          this.target_groups = data
        }
        this.isEditingMode = true
        this.currentGroup.is_editing = true
        this.$emit("start-editing")
      }
    },
    computed: {
      availableTargetGroups() {
        return this.target_groups.filter(g => ! this.currentGroup.targets.find(gg => gg.id === g.id)).sort((a, b) => {
            if(a.name < b.name) { return -1 }
            if(b.name < a.name) { return 1 }
            return 0
        })
      },
      sortedCurrentTargetGroups() {
        const fakeClone = this.currentGroup.targets
        return fakeClone.sort((a, b) => {
            if(a.name < b.name) { return -1 }
            if(b.name < a.name) { return 1 }
            return 0
        })
      },
      groupInfo() {
        return this.currentGroup.id ? this.groupstypes.find(g => g.id === this.currentGroup.id) : null
      },
      textSaveButton() {
        return this.hasAlreadyBeenAdded ? 'Update' : 'Add it !'
      },
      availableGroups() {
        return this.groupstypes.filter(gt => this.reservedIds.indexOf(gt.id) === -1 || (this.currentGroup.id && gt.id === this.currentGroup.id) )
      }
    }
  }
</script>
<style scoped lang="sass">
  .target-groups-panel
    label
      font-size: 1.2em

  .tgp-groups
    background-color: white
    padding: 20px
    margin-bottom: 20px

  .tgp-selector
    display: flex
    flex-direction: row
    align-items: top
    flex-wrap: 1

    .tgp-selector-available, .tgp-selector-selected
      flex-grow: 1
      padding: 1em
      border: 1px solid #D1D1D1
      border-radius: 10px

      strong
        display: block
        margin-bottom: 10px

    .tgp-selector-actions
      flex-shrink: 1
      display: flex
      flex-direction: column
      justify-content: top
      align-items: center

    .tgp-item
      cursor: pointer
      padding: 5px
      line-height: 3em
      vertical-align: center

      &:hover
        &:not(.selected)
          background-color: #D9D9D9

      &:nth-child(even)
        background-color: #E1E1E1
        &:hover
          &:not(.selected)
            background-color: #D9D9D9

      &.selected
        background-color: #f4b944

  .tgp-actions
    display: flex
    flex-direction: row
    margin-top: 10px
    justify-content: flex-start
    align-items: center

    > span
      margin: 0 6px
    > a
      margin: 0 6px
      text-decoration: underline
      cursor: pointer

  .show-me
    background-color: white
    margin: 8px 0px
    border-radius: 5px
    padding: 1em
    display: flex
    flex-direction: row
    align-items: center

    .main-info
      flex-grow: 1

    .head
      font-size: 1.1em
      font-weight: bold

    .target-chips
      display: inline-block
      background-color: #f4b944
      font-size: 0.9em
      padding: 2px 15px
      margin: 0px 5px
      border-radius: 9px
      line-height: 2em

      &:nth-child(1)
        margin-left: 0
</style>
