<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        fixed
      >
        <v-btn
          icon
          @click="cancel"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="visitor.id">
          Edit the participant
        </v-toolbar-title>
        <v-toolbar-title v-else>
          New participant
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          flat
          :disabled="loading"
          @click="save"
        >
          Save the participant
        </v-btn>
      </v-toolbar>

      <v-card-text
        layout="row"
        layout-align="center center"
        class="mt-5 pt-5"
      >
        <v-form
          ref="visitorForm"
          class="field-width"
        >
          <div class="title font-weight-light mb-4">
            Details of the participant
          </div>

          <v-text-field
            v-model="visitor.unregister_firstname"
            outline
            label="Firstname *"
            :rules="[RULES.Required]"
          />

          <v-text-field
            v-model="visitor.unregister_lastname"
            outline
            label="Lastname *"
            :rules="[RULES.Required]"
          />

          <v-text-field
            v-model="visitor.unregister_email"
            outline
            label="Email address *"
            :rules="[
              RULES.Required,
              v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid']
            "
          />

          <v-text-field
            v-model="visitor.unregister_phone"
            outline
            label="Phone number"
          />

          <div class="text-xs-right">
            <v-btn
              depressed
              color="primary"
              :disabled="loading"
              @click="save"
            >
              Save the participant
            </v-btn>
            or
            <a
              href="#"
              class="black--text"
              @click.prevent="cancel"
            >Cancel</a>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
    import RulesMixin from '@/mixins/rules'

    export default {
        mixins: [RulesMixin],
        props: {
            value: {
                type: Object,
                required: false,
                default () { return {unregister_firstname: "", unregister_lastname: "", unregister_email: "", unregister_phone: ""} }
            },
            visible: {
                type: Boolean,
                required: false,
                default: false
            },
            session: {
              type: Object,
              required: true
            }
        },
        data () {
            return {
                loading: false
            }
        },
        computed: {
            visitor: {
                get () {
                    return this.value;
                },
                set (x) {
                    this.$emit('input', x);
                }
            },
            isVisible: {
                get () {
                    return this.visible;
                },
                set (x) {
                    this.$emit('update:visible', x);
                }
            }
        },
        watch: {
          visible() {
            if(!this.visitor.unregister_email) {
              this.$refs['visitorForm'].reset() // c'est pour ne pas avoir du rouge en réouvrant le form
            }
          }
        },
        methods: {
            cancel () {
                this.isVisible = false
            },
            save () {
                if (!this.$refs['visitorForm'].validate()) {
                    this.$snotify.error('Please check the form for invalid values');
                    return;
                }
                this.isVisible = false
                this.$emit('saved', this.visitor)
            }
        }
    }
</script>
<style scoped>
.erliewen-block{
  background-color: #EEEEEE;
}
</style>
