<template>
  <div>
    <div
      layout="row"
      layout-align="start start"
      flex
      class="mb-2 full-width"
      style="align-items: stretch"
    >
      <div
        layout="column"
        class="tuile mr-2 grow"
        flex
      >
        <v-subheader>Organization</v-subheader>
        <div class="px-3 mb-2">
          <div
            layout="row"
            class="font-weight-bold title mb-2"
          >
            <div layout="column">
              <div
                v-if="organization.is_school"
                layout="row"
                layout-align="start start"
                class="subheading"
              >
                {{ organization.school_type.name }}
              </div>
              <div
                v-else
                layout="row"
                layout-align="start start"
                class="subheading"
              >
                Institution
              </div>
              <div class="inline-flex">
                <div
                  v-if="organization.client_no"
                  layout="row"
                  layout-align="start start"
                  class="subheading mb-2"
                >
                  #{{ organization.client_no }}
                </div>
                <div
                  v-if="organization.peppol_number"
                  layout="row"
                  layout-align="start start"
                  class="subheading mb-2"
                >
                  #{{ organization.peppol_number }}
                </div>
              </div>
              <div
                layout="row"
                layout-align="start start"
              >
                {{ organization.name }}
              </div>
            </div>
          </div>
          <div layout="row">
            {{ organization.number }} {{ organization.street }}
          </div>
          <div layout="row">
            {{ organization.zip_code }} {{ organization.city }}
            <a
              :href="'https://www.google.com/maps/search/?api=1&query=' + organization.number + ' '+ organization.street + ' ' + organization.zip_code + ' ' + organization.city"
              target="_blank"
            >
              <v-icon
                class="ml-4 right"
                color="primary"
                size="20"
                title="Show in Google Maps"
              >mdi-map-marker</v-icon>
            </a>
          </div>
          <div layout="row">
            {{ organization.phone }}
          </div>
          <div
            layout="row"
            class="my-2"
          >
            <span class="link-unstyled">{{ organization.email }}</span>
          </div>
          <div
            v-if="organization.parking && organization.parking.length > 0"
            layout="row"
          >
            <span class="body-2 font-weight-medium mr-2">Parking facilities: </span>
            <span class="nl2br">{{ organization.parking }}</span>
          </div>
        </div>
      </div>

      <div
        layout="column"
        class="tuile grow"
        flex
      >
        <v-subheader>Evaluations</v-subheader>
        <div
          layout="row"
          class="px-3 mb-2 full-width"
        >
          <div
            layout="column"
            layout-align="center center"
            class="grow"
          >
            <div>
              <v-rating
                background-color="#AAAAAA"
                dense
                large
                class="mr-1"
                readonly
                :value="parseInt(organization.mark)"
              />
            </div>
            <div
              layout="row"
              class="display-3 font-weight-bold"
              layout-align="start center"
            >
              <span>{{ organization.mark }}</span>
              <span class="evals-nb ml-2">
                with {{ evaluations.length }} evaluation{{ evaluations.length > 1 ? 's' : '' }}
              </span>
            </div>
          </div>
          <v-divider
            vertical
            class="mx-3"
          />
          <div
            v-if="evaluations.length"
            layout="column"
          >
            <div
              layout="row"
              layout-wrap
              class="grow"
              layout-align="start start"
            >
              <div
                v-if="evaluations && evaluations[0]"
                class="px-2 mb-2"
              >
                <div>
                  <div
                    layout="row"
                    class="font-weight-bold"
                  >
                    {{ evaluations[0].session_trainer.user.firstname }} {{ evaluations[0].session_trainer.user.lastname[0] }}.
                  </div>
                  <div layout="row">
                    {{ formatDateHuman(evaluations[0].created_at) }}
                  </div>
                </div>
                <div>
                  <div layout="row">
                    <v-rating
                      background-color="#AAAAAA"
                      dense
                      small
                      readonly
                      :value="parseInt(evaluations[0].school_mark)"
                    />
                  </div>
                  <div layout="row">
                    {{ evaluations[0].orga_aspects }}
                  </div>
                </div>
              </div>
              <div
                v-if="evaluations && evaluations[1]"
                class="px-2 mb-2"
              >
                <div>
                  <div
                    layout="row"
                    class="font-weight-bold"
                  >
                    {{ evaluations[1].session_trainer.user.firstname }} {{ evaluations[1].session_trainer.user.lastname[1] }}.
                  </div>
                  <div layout="row">
                    {{ formatDateHuman(evaluations[1].created_at) }}
                  </div>
                </div>
                <div>
                  <div layout="row">
                    <v-rating
                      background-color="#AAAAAA"
                      dense
                      small
                      readonly
                      :value="parseInt(evaluations[0].school_mark)"
                    />
                  </div>
                  <div layout="row">
                    {{ evaluations[1].orga_aspects }}
                  </div>
                </div>
              </div>
              <div
                flex="100"
                layout="row"
                layout-align="center"
                class="mt-3"
              >
                <router-link :to="{name: 'address-show-evals', params: {id: organization.id}}">
                  See all evaluations
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 tuile-contacts">
      <div
        layout="row"
        layout-align="space-between center"
      >
        <v-subheader>Contacts</v-subheader>
        <v-btn
          v-if="isAdmin || isCoord"
          flat
          color="primary"
          @click="newContact()"
        >
          <v-icon
            class="mr-1"
            color="primary"
          >
            mdi-plus
          </v-icon><span class="mt-1">new contact</span>
        </v-btn>
      </div>

      <v-layout
        row
        wrap
        class="px-2"
      >
        <v-flex
          v-for="c in contacts"
          :key="c.id"
          xs12
          md6
          lg4
          xl3
          class="px-2 pb-3"
        >
          <v-card
            style="height: 100%"
            class="grey lighten-5"
          >
            <v-card-title class="pa-2 grey lighten-3">
              <div layout="row">
                <div layout="column">
                  <v-avatar color="grey">
                    <span class="title white--text">{{ c.firstname[0] }}{{ c.lastname[0] }}</span>
                  </v-avatar>
                </div>
                <div
                  layout="column"
                  class="ml-2"
                  layout-align="center start"
                  flex
                >
                  <div class="font-weight-bold">
                    <span v-show="c.title">{{ c.title }}. </span>
                    {{ c.firstname }} {{ c.lastname }}
                  </div>
                  <div
                    v-if="c.function"
                    class="grey--text"
                  >
                    {{ c.function.name }}
                  </div>
                </div>
              </div>
              <v-spacer />
              <div v-if="isAdmin || isCoord">
                <v-btn
                  icon
                  title="Edit"
                  class="ml-4"
                  @click="editContact(c)"
                >
                  <v-icon color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  :title="c.is_enabled ? 'Disable' : 'Enable'"
                  @click="toggleContact(c)"
                >
                  <v-icon color="primary">
                    mdi-lock{{ c.is_enabled ? '' : '-open' }}
                  </v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-list class="pt-0 grey lighten-5">
              <!--<template v-if="c.lang">
                <v-list-tile>
                  <v-list-tile-action>
                    <v-icon color="accent">mdi-web</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>{{c.lang ? c.lang.name : ''}}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider inset></v-divider>
              </template>-->

              <template v-if="c.phone">
                <v-list-tile>
                  <v-list-tile-action>
                    <v-icon color="accent">
                      mdi-phone
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ c.phone }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider inset />
              </template>

              <template v-if="c.email">
                <v-list-tile>
                  <v-list-tile-action>
                    <v-icon color="accent">
                      mdi-email
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ c.email }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </div>

    <div
      layout-gt-md="row"
      layout-align="start start"
      layout-wrap
      class="mt-2 tuile"
    >
      <div
        layout="column"
        flex="80"
      >
        <v-subheader>Sessions</v-subheader>
      </div>
      <div
        layout="column"
        flex="20"
      >
        <div
          layout="row"
          layout-align="end center"
          class="mx-4 pt-2"
        >
          <span class="font-weight-bold ml-4 display-3">
            {{ sessions.length }}
          </span>
          <span class="evals-nb ml-2">
            session{{ sessions.length > 1 ? 's' : '' }}
          </span>
        </div>
      </div>
      <div
        layout="row"
        class="font-weight-bold title mx-auto px-3"
        style="width: 100%"
      >
        <canvas
          v-if="sessionsData"
          id="myChart"
          style="width: 90%"
        />
      </div>

      <div
        layout="row"
        layout-align="center"
        class="pr-4 py-3"
        flex="100"
      >
        <router-link :to="{name: 'address-show-sessions', params: {id: organization.id}}">
          See all sessions
        </router-link>
      </div>
    </div>

    <contact-dialog
      :visible.sync="contactDialog"
      :organization="$route.params.id"
      :value="editingContact"
      @created="fetch"
      @updated="fetch"
    />
  </div>
</template>

<script>
import Organizations from '@/api/organizations'
import Sessions from '@/api/sessions'
import dateMixin from '@/mixins/date'
import moment from 'moment'
import ContactDialog from "@/components/shared/ContactDialog.vue"
import Chart from "chart.js"

export default {
  components: { ContactDialog },
  mixins: [dateMixin],
  props: ['organization', 'isAdmin', 'isCoord'],

  data () {
    return {
      evaluations: [],
      sessions: [],
      years: [],
      sessionsData: [],
      contacts: [],
      contactDialog: false,
      editingContact: {}
    }
  },

  watch: {
    contactDialog (val) {
      if (!val) {
        this.editingContact = {}
      }
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    fetch () {
      Organizations.getEvals(this.$route.params.id).then(res => {
        this.evaluations = res.data
      })
      Sessions.getList({ organization_id: this.$route.params.id }).then(res => {
        this.sessions = res.data
        this.loadGraph()
      })
      Organizations.getContactList(this.$route.params.id).then(res => {
        this.contacts = res.data
      })
    },
    isInt (value) {
      var x
      if (isNaN(value)) {
        return false
      }
      x = parseFloat(value)
      return (x | 0) === x
    },
    loadGraph () {
      //Labels pour le graph
      let currentYear = moment().format('YYYY')
      for (let i = currentYear - 10; i <= currentYear; i++) {
        this.years.push(i)
        this.sessionsData[i] = 0
      }

      //Données du graph : nb de sessions par an
      for (let i = 0; i < this.sessions.length; i++) {
        if (this.sessions[i].dates && this.sessions[i].dates.length > 0) {
          let sYear = moment(this.sessions[i].dates[0].start).format('YYYY')

          if (sYear in this.sessionsData) {
            this.sessionsData[sYear]++
          }
        }
      }

      let dataGraph = this.sessionsData.filter(function () { return true })

      var ctx = document.getElementById("myChart").getContext('2d')
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.years,
          datasets: [{
            data: dataGraph,
            backgroundColor: 'transparent',
            borderColor: '#f4b944',
            pointRadius: 6,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 3,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 4,
            pointHitRadius: 10,
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: (value) => { if (this.isInt(value)) { return value } }
              },
              display: true
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
        },
      })
    },
    newContact () {
      this.contactDialog = true
    },
    editContact (contact) {
      this.editingContact = Object.assign({}, contact)
      this.contactDialog = true
    },
    toggleContact (contact) {
      if (contact.is_enabled) {
        contact.is_enabled = 0
        Organizations.disableContact(this.$route.params.id, contact.id).then(() => {
          this.$snotify.success('The contact has been disabled')
        })
      } else {
        contact.is_enabled = 1
        Organizations.enableContact(this.$route.params.id, contact.id).then(() => {
          this.$snotify.success('The contact has been enabled')
        })
      }
    }
  }
}
</script>

<style scoped>
.panel {
  border-top: 4px solid #888888;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 16px;
}

.panel-contact {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 16px;
}

.expo {
  vertical-align: super;
  font-size: 14px;
  color: black;
}

.evals-nb {
  color: lightgrey;
  font-size: 18px;
}

canvas {
  /*width: 1000px !important;*/
  height: 400px !important;
}

.tuile {
  background-color: white;
}

.tuile-contacts {
  background-color: white;
}

@media screen and (min-width: 1280px) {
  .panel.half {
    width: calc(50% - 8px);
  }

  .panel.third {
    width: calc(33.33333% - 8px);
  }
}

.inline-flex {
  display: flex;
  flex-direction: row;
}

.inline-flex > div {
  margin-right: 15px;
}
</style>
