<template>
  <div class="ma-3">
    <div class="title mb-3">
      Sessions
      <v-icon>mdi-chevron-right</v-icon>
      Concepts management
      <v-icon>mdi-chevron-right</v-icon>
      {{ topic.name }}
      <v-icon>mdi-chevron-right</v-icon>
      Incompatibilities rules
    </div>
    <div id="solo-choice">
      <v-switch
        v-model="is_concomitence_possible"
        :label="switchLabel"
        @change="updateTopic"
        class="switch"
      ></v-switch>
    </div>
    <div v-if="is_concomitence_possible">
      <div class="info-block">
        <v-icon size="40px" color="#4b8e9a" class="info-icon">mdi-information-variant-box</v-icon>
        You can complete the list of incompatible topics below with <strong>`{{topic.name}}`</strong>
      </div>
      <div id="incompatibilities-container">
        <div class="form">
          <v-autocomplete
            v-model="addingZone"
            :items="availableTopics"
            item-text="name"
            item-value="id"
            v-focus
            chips
            clearable
            deletable-chips
            multiple
            :placeholder="`Please select topics you want to declare incompatible with ${topic.name}`"
          ></v-autocomplete>
          <v-btn
            depressed
            color="primary"
            @click="addIncompatibilities"
            :disabled="addingZone.length === 0"
          >Add</v-btn>
        </div>
        <div class="flex-container">
          <div class="centered-bloc">
            <div v-for="i in incompatibilitiesSorted" :key="i.id" class="incompatibility">
              <div class="name">{{i.name}}</div>
              <div class="action">
                <delete-button
                  :label="i.name"
                  title="Delete this incompatibility"
                  @confirm="deleteIncompatibility(i)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Params from "@/api/params"
  import GlobalMixin from "@/mixins/global"
  import Consts from "@/consts.js"
  import DeleteButton from "@/components/shared/DeleteButton.vue"

  export default {
    components: {DeleteButton},
    mixins: [GlobalMixin],
    props: {
      value: {
        type: Object,
      }
    },
    data() {
      return {
        topic: {is_solo_compatible: true},
        is_concomitence_possible: false,
        topics: [],
        incompatibilities: [],
        addingZone: []
      };
    },
    mounted() {
      this.topic = structuredClone(this.value)
      this.is_concomitence_possible = ! this.topic.is_solo_compatible //on inverse la logique graphiquement
      if(this.is_concomitence_possible) {
       this.fetch()
      }
    },
    methods: {
      async fetch() {
        //tous les topics
        const {data: topicsData} = await Params.getList('topics', {is_enabled: 1, sortBy: "name", application: Consts.APPLICATION.FREELANCES})
        this.topics = topicsData
        //toutes les incompatibilités déjà existantes
        const {data: incompatibilitiesData} = await Params.getList(`topics/${this.topic.id}/incompatibilities`)
        this.incompatibilities = incompatibilitiesData
      },
      async updateTopic() {
        const way = this.is_concomitence_possible ? 'unsolotize' : 'solotize'
        const {data} = await axios.patch(`/params/topics/${this.topic.id}/${way}`)
        this.$emit("topic:update", data)
        if(this.is_concomitence_possible) {
         this.fetch()
        }
        this.$snotify.success(
          `The concept "${this.topic.name}" has been updated`
        )
      },
      async addIncompatibilities() {
        if(this.addingZone.length > 0) {
          const {data} = await axios.post(`/params/topics/${this.topic.id}/incompatibilities/multiple-adding`, {incompatibilities: this.addingZone})
          this.incompatibilities = data
          this.addingZone.splice(0)
          this.$snotify.success(
            `The incompatibilities with "${this.topic.name}" have well been created`
          )
        }
      },
      async deleteIncompatibility(i) {
        try {
          await Params.delete(`topics/${this.topic.id}/incompatibilities`, i.id)
          this.incompatibilities.splice(this.incompatibilities.indexOf(this.incompatibilities.find(ii => ii.id == i.id)), 1)
          this.$snotify.success(
            `The incompatibility "${i.name}" with "${this.topic.name}" has been deleted with success`
          )
        }
        catch(e) {
          console.log(e)
        }
      }
    },
    computed: {
      switchLabel() {
        return this.is_concomitence_possible ? 'This concept can be delivered in the same time than other activities' : 'This concept can\'t  be delivered simultaneously with another activity'
      },
      availableTopics() {
        return this.topics.filter(t => t.id != this.topic.id && ! this.incompatibilities.find(i => i.topic_id == t.id || i.incompatible_topic_id == t.id))
      },
      incompatibilitiesSorted() {
        return this.incompatibilities.map(i => {
          const name = i.topic.id == this.topic.id ? i.incompatible_topic.name : i.topic.name
          return {id: i.id, name}
        }).sort((a, b) => {
          if(a.name < b.name) { return -1 }
          if(b.name < a.name) { return 1 }
          return 0
        })
      }
    }
  }
</script>
<style scoped lang="sass">
  #solo-choice
    background-color: white
    display: flex
    flex-direction: inline
    justify-content: center
    align-items: center
    padding: 10px

  .info-block
    padding: 20px
    margin-top: 20px
    display: flex
    flex-direction: row
    align-items: center
    background-color: #ccf7ff
    color: #4b8e9a

    .info-icon
      margin-right: 20px

  #incompatibilities-container
    .form
      display: flex
      flex-direction: row
      align-items: top

      button
        margin-top: 15px

  .flex-container
    display: flex
    flex-direction: row
    justify-content: center
    width: 100%

  .centered-bloc
    width: 400px

    .incompatibility
      display: flex
      flex-direction: row
      align-items: center

      &:nth-child(odd)
        background-color: white

      .name
        flex-grow: 1
        padding: 0 10px


</style>
